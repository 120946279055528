import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import SingleCommentForFood from "./SingleCommentForFood";
import { useParams, useLocation } from "react-router-dom";
import { ImStarFull } from "react-icons/im";
import URLContext from "./URLContext";
import "./Animation.css";
import logo from "./images/logo_to_spin.png";
import AboutUs from "./AboutUs";
import ReactGA from "react-ga4";

export default function FoodPage(props) {
  var { foodName } = useParams();
  var location = useLocation();
  var score = location.state.score;
  var voteCount = location.state.voteCount;
  // var token_ = props.token.Authorization;
  const baseURL = useContext(URLContext);

  const [commentsRetrieved, setCommentsRetrieved] = useState(false);

  const [comments, setComments] = useState([]);
  const [username, setUsername] = useState("");

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "page view",
    });

    var url;
    if (props.token == null) {
      url = baseURL + "/get-food-info-visitor";
    } else {
      url = baseURL + "/get-food-info";
      var parts = props.token.Authorization.split(".");
      const [header, payload] = parts;
      const decodedPayload = JSON.parse(atob(payload));
      setUsername(decodedPayload["cognito:username"]);
    }
    const headers = props.token;
    axios
      .post(
        url,
        { foodName: foodName.turkishToUpper() },
        {
          headers,
        }
      )
      .then((response) => {
        console.log("food page:", response.data);
        setCommentsRetrieved(true);
        setComments(response.data);
      });
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={headerContainer}>
        <Header />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}> {foodName}</p>
      </div>
      <div
        style={{
          display: "flex",
          //backgroundColor: "white",
          flexDirection: "row",
          justifyContent: "space-around",
          alignItems: "center",
          height: "40px",
        }}
      >
        <div
          style={{
            margin: "3px",
            // fontFamily: "Ubuntu",
            fontWeight: "bold",
            fontSize: "20px",
          }}
        >
          {score}
        </div>
        <ImStarFull size="16px" />{" "}
        <p style={{ marginLeft: "5px" }}>({voteCount})</p>
      </div>

      <div
        onClick={() => {
          // Deleting token

          window.location.reload();
        }}
        style={{
          // fontFamily: "Ubuntu",
          fontSize: "14px",
          marginTop: "20px",
          marginBottom: "20px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {/* <Link style={{ color: "black" }} to="/">
          Ana menüye dön
        </Link> */}
      </div>

      <div
        style={{
          borderTop: "0px inset black",
          display: "flex",
          //backgroundColor: "red",
          width: "340px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <p>Tüm Yorumlar</p>
        {commentsRetrieved ? (
          comments.map((comment) => (
            <SingleCommentForFood
              key={comment.commentId}
              comment={comment}
              token={props.token}
              isLoggedIn={props.token != null}
              username={username}
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "80px",
                animation: `spin ${3}s linear infinite`,
              }}
              src={logo}
              alt="Logo"
            />
          </div>
        )}
      </div>
      <div style={bottomBlank}></div>
      <AboutUs />
      <div style={bottomBlank}></div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

// STYLES
const bottomBlank = {
  height: "100px",
};

const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};
