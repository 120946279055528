import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import DiscoverComment from "./DiscoverComment";
import { useParams } from "react-router-dom";
import { ImStarFull } from "react-icons/im";
import { FaCrown } from "react-icons/fa";
import URLContext from "./URLContext";
import "./Animation.css";
import logo from "./images/logo_to_spin.png";
import AboutUs from "./AboutUs";
import ReactGA from "react-ga4";
// import { LoggedInContext } from "./LoggedInContext";

export default function DiscoverPage(props) {
  var isLoggedIn = props.token != null;

  const baseURL = useContext(URLContext);
  // const { isLoggedIn } = useContext(LoggedInContext);

  const [commentsRetrieved, setCommentsRetrieved] = useState(false);

  const [comments, setComments] = useState([
    // {
    //   commenter: "test",
    //   text: "Güzel olmuş Macar Dolması mıdır nedir",
    //   score: 1,
    //   commentId: "COMMENT#test#MACAR GULAŞ#2023-07-20",
    //   to: "MACAR GULAŞ ASD DASDASD DSADSAD SADASDASD ASDSA DSA",
    //   date: "2023-07-21T09:41:14",
    //   isLiked: false,
    //   isDisliked: false,
    // },
    // {
    //   commenter: "harun",
    //   text: "Harun",
    //   score: 0,
    //   commentId: "COMMENT#harun#HAVUÇ TARATOR#2023-07-21",
    //   to: "HAVUÇ TARATOR",
    //   date: "2023-07-21T17:20:04",
    //   isLiked: false,
    //   isDisliked: false,
    // },
  ]);
  const [mealOfWeek, setMealOfWeek] = useState([
    // { type: "Çorba", name: "Mercimek Çorbası", score: 4.8 },
    // { type: "Ana Yemek", name: "Etli Pilav", score: 4.1 },
    // { type: "Yan Yemek", name: "Bulgur Pilavı", score: 4.2 },
    // { type: "Salata", name: "Çoban Salata", score: 4.9 },
    // { type: "Garnitür", name: "Nohut", score: 3.2 },
  ]);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  };

  String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
      return letters[letter];
    });
    return string.toLowerCase();
  };

  const convertToCamelCase = (str) => {
    const arr = str.turkishToLower().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).turkishToUpper() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");
    return str2;
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "page view",
    });
    // setIsLoggedIn(JSON.parse(localStorage.getItem("isVisitor")) != null);
    var url;
    if (isLoggedIn) {
      url = baseURL + "discovery";
    } else {
      url = baseURL + "discovery-visitor";
    }

    const headers = props.token;
    console.log(headers);
    axios
      .get(url, {
        headers,
      })
      .then((response) => {
        console.log("data:", response.data["bestComments"]);
        setMealOfWeek(response.data["bestFoods"]);
        setCommentsRetrieved(true);
        setComments(response.data["bestComments"]);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={headerContainer}>
        <Header />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}></p>
      </div>
      <div
        style={{
          display: "flex",
          //backgroundColor: "white",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
          height: "30px",
          marginBottom: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <FaCrown />
          <FaCrown style={{ margin: "5px" }} />
          <FaCrown />
        </div>
        <div
          style={{
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          Haftanın Menüsü
        </div>
      </div>
      <div
        style={{
          // borderTop: "1px inset black",
          display: "flex",
          width: "340px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {mealOfWeek.map((meal) => (
          <div
            key={meal.id}
            style={{
              flexDirection: "column",
              // backgroundColor: "red",
              width: "300px",
              height: "100px",
              borderTop: "1px inset rgba(0, 0, 0, 0.3)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                // backgroundColor: "yellow",
              }}
            >
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                {meal.type}
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontFamily: "Nunito",
                  fontWeight: 700,
                }}
              >
                {meal.score} <ImStarFull size={12} />
              </p>
            </div>
            <div
              style={{
                // display: "flex",
                // backgroundColor: "green",
                flex: 1,
              }}
            >
              <Link
                style={{ color: "black", textDecoration: "underline" }}
                to={{
                  pathname: "/foods/" + convertToCamelCase(meal.name),
                  state: { score: meal.score, voteCount: meal.voteCount },
                }}
              >
                <p
                  style={{
                    fontSize: 14,
                    fontFamily: "Nunito",
                    fontWeight: 300,
                  }}
                >
                  {convertToCamelCase(meal.name)}
                </p>
              </Link>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.3)", width: "300px" }}
      ></div>
      {commentsRetrieved ? (
        <p
          style={{
            fontFamily: "Nunito",
            fontWeight: 700,
            fontSize: "20px",
          }}
        >
          En Beğenilen Yorumlar
        </p>
      ) : null}
      <div
        style={{
          // borderTop: "1px inset black",
          display: "flex",
          // backgroundColor: "red",
          width: "340px",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {commentsRetrieved ? (
          comments.map((comment) => (
            <DiscoverComment
              key={comment.commentId}
              comment={comment}
              token={props.token}
              showLikeButtons={isLoggedIn}
            />
          ))
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "80px",
                animation: `spin ${3}s linear infinite`,
              }}
              src={logo}
              alt="Logo"
            />
          </div>
        )}
      </div>
      <div style={bottomBlank}></div>
      <AboutUs />
      <div style={bottomBlank}></div>
      <div>
        <Footer page="discover" />
      </div>
    </div>
  );
}

// STYLES
const bottomBlank = {
  height: "100px",
};

const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};
