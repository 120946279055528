import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import { TextInput } from "react-native";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import axios from "axios";

import "./Animation.css";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export default function AdminPage(props) {
  const [reportedComments, setReportedComments] = useState([]);

  const handleAction = async (type_, commentId) => {
    var url =
      "https://yh2duiaxd1.execute-api.eu-central-1.amazonaws.com/prod/reported-comments/action/";

    var headers = props.token;
    axios
      .post(
        url,
        { action: type_, commentId: commentId },
        {
          headers,
        }
      )
      .then((response) => {
        console.log("success");
        setReportedComments((prevComments) =>
          prevComments.filter((comment) => comment.reportedId !== commentId)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    var parts = props.token.Authorization.split(".");
    const [header, payload] = parts;
    const decodedPayload = JSON.parse(atob(payload));
    var username = decodedPayload["cognito:username"];
    if (username != "harun" && username != "tayyib") {
      window.location.href = "/";
    } else {
      var url =
        "https://yh2duiaxd1.execute-api.eu-central-1.amazonaws.com/prod/reported-comments";

      var headers = props.token;
      axios
        .get(url, {
          headers,
        })
        .then((response) => {
          console.log(response.data.reportedComments);
          setReportedComments(response.data.reportedComments);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {reportedComments.length > 0 ? (
        <div style={{ textAlign: "center" }}>
          <div style={headerContainer}>
            <Header />
          </div>
          <div style={menuTextContainerStyle}>
            <p style={menuTextStyle}></p>
          </div>

          <p
            style={{
              // fontFamily: "Ubuntu",
              fontWeight: "bold",
              fontSize: "20px",
              align: "center",
            }}
          >
            Admin Sayfası
          </p>
          {reportedComments.map((item) => {
            return (
              <div
                key={item.reportedId}
                style={{
                  width: "300px",
                  backgroundColor: "rgba(0, 0, 0, 0.1)",
                  borderRadius: "10px",
                  margin: "10px",
                  padding: "10px",
                  textAlign: "start",
                }}
              >
                <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6" }}>
                  {item.ts.split("#")[0]} {item.ts.split("#")[1]}
                </p>

                <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6" }}>
                  report count: {item.reportCount}
                </p>
                <p>comment: {item.comment.text_}</p>

                {item.comment.imageUrl.length > 0 && (
                  <img
                    src={item.comment.imageUrl}
                    style={{
                      borderRadius: "6px",
                      borderTopLeftRadius: "0px",
                      borderTopRightRadius: "0px",
                      // border: "1px white solid",
                      width: "100%",
                    }}
                  />
                )}
                <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6" }}>
                  commenter: {item.reportedId.split("#")[1]}
                </p>
                <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6" }}>
                  food: {item.reportedId.split("#")[2]}
                </p>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <button
                    onClick={() => {
                      handleAction("delete", item.reportedId);
                    }}
                    style={{
                      borderRadius: "4px",
                      color: "white",
                      backgroundColor: "red",
                    }}
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      handleAction("discard", item.reportedId);
                    }}
                    style={{
                      borderRadius: "4px",
                      color: "white",
                      backgroundColor: "green",
                    }}
                  >
                    Discard
                  </button>
                </div>
                <p></p>
              </div>
            );
          })}
        </div>
      ) : (
        "şikayet yok"
      )}
    </div>
  );
}

// STYLES

const profilePicture = {
  width: "128px",
  height: "128px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "-64px",
  border: "rgb(132, 61, 61) 4px solid",
};

const pp = {
  color: "rgb(132, 61, 61)",
};

const row = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#8c4343",
  width: "300px",
  margin: "10px",
  borderRadius: "4px",
};

const card = {
  backgroundColor: "rgb(132, 61, 61)",
  width: "340px",
  marginTop: "80px",
  borderRadius: "20px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
};

const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};

const buttonStyle = {
  backgroundColor: "#843d3d",
  width: "200px",
  height: "40px",
  borderRadius: "10px",
  border: "none",
  color: "#e3e1e1",
  fontSize: "14px",
  // fontFamily: "Ubuntu",
  fontWeight: "bold",
  cursor: "pointer",
};
