import React from "react";
import { Link } from "react-router-dom";
import logo from "./images/logo.png";
import { FaRegCompass } from "react-icons/fa";
import {
  RiHome4Line,
  RiHome4Fill,
  RiUserFill,
  RiUserLine,
  RiCompass3Fill,
  RiCompass3Line,
} from "react-icons/ri";

export default function Footer({ page }) {
  return (
    <div style={footer}>
      <button style={footerButton}>
        <Link to="/">
          {page == "home" ? (
            <RiHome4Fill style={iconStyle} size={30} />
          ) : (
            <RiHome4Line style={iconStyle} size={30} />
          )}
        </Link>
      </button>
      <button style={footerButton}>
        <Link to="/discover">
          {page == "discover" ? (
            <RiCompass3Fill style={iconStyle} size={30} />
          ) : (
            <RiCompass3Line style={iconStyle} size={30} />
          )}
        </Link>
      </button>

      <button style={footerButton}>
        <Link to="/profile/">
          {page == "profile" ? (
            <RiUserFill style={iconStyle} size={30} />
          ) : (
            <RiUserLine style={iconStyle} size={30} />
          )}
        </Link>
      </button>
    </div>
  );
}

// STYLES
const footer = {
  position: "fixed",
  bottom: "0",
  left: "0",
  right: "0",
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  height: "48px",
  borderTop: "1px white solid",
  backgroundColor: "rgba(132, 61, 61, 0.98)",
};

const footerButton = {
  border: "none",
  backgroundColor: "transparent",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#333",
};

const iconStyle = {
  color: "white",
};
