import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import axios from "axios";
import DiscoverComment from "./DiscoverComment";
import { useParams } from "react-router-dom";
import { ImStarFull } from "react-icons/im";
import { FaCrown } from "react-icons/fa";
import URLContext from "./URLContext";
import "./Animation.css";
import logo from "./images/logo_to_spin.png";
import { RiUserFill } from "react-icons/ri";
import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import AboutUs from "./AboutUs";
import awsExports from "./aws-exports";
import ReactGA from "react-ga4";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export default function ProfilePage(props) {
  var isLoggedIn = props.token != null;

  const baseURL = useContext(URLContext);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [dataRetrieved, setDataRetrieved] = useState(false);
  const [userName, setUserName] = useState();
  const [userData, setUserData] = useState({
    // gurmeScore: "17",
    // commentCount: "1",
    // foodRateCount: "8",
    // foodRateAverage: "3.6",
    // mealLikeCount: "1",
    // mealDislikeCount: "0",
    // joinDate: "2023-07-20",
    // lastOperationTime: "2023-07-21T12:00:17",
  });

  const handleLogout = async () => {
    await Auth.signOut()
      .then((response) => {
        localStorage.removeItem("isVisitor");
        window.location.assign("/");
      })
      .catch((err) => {});
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "page view",
    });
    if (!isLoggedIn) {
      localStorage.removeItem("isVisitor");
      window.location.assign("/");
    } else {
      // var isTokenNotVisitor =
      //   JSON.parse(localStorage.getItem("token"))["Authorization"] !=
      //   "eyJraWQiOiJvOTRSOWhCQ2dKTXJhcHVycVFqa0cxZURyWHh0bFFyYUt0OXA1S2p6WFdnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMzE0MzhmMi00MGIxLTcwOGUtOGNiNi04OGI3MjhlZGU4MDkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYTI4V24zbVN0IiwiY29nbml0bzp1c2VybmFtZSI6InZpc2l0b3IiLCJvcmlnaW5fanRpIjoiN2ZiY2YxZjctNWU2OS00NWFmLWIyZGUtY2VjZTZmNWYwNmE2IiwiYXVkIjoiMmtnbXY5dWFkbmZzNnFkZTY5cGQ3ZmJlMWwiLCJldmVudF9pZCI6Ijc4ZDZhYWZhLWMwYmQtNDMyNC1hNzU0LWI5YWMzYzBiYmU5MiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjkxNTk3MDcwLCJleHAiOjE2OTE2MDA2NzAsImlhdCI6MTY5MTU5NzA3MCwianRpIjoiYzQ3ZDY3OWEtYzI3OC00NzM4LWE0Y2EtNjE5MjE0ZWUzMDdiIiwiZW1haWwiOiJoYXJ1bnRya21uQGdtYWlsLmNvbSJ9.Vo_S6pFcmo44_wlfB0Zxx4rIMY4Ye8ii9eWoKBCiIdrFpoSAkfRpo09O5TBEin001NJbqoGmt0ueFgSjnrfglOhl4_AxHkSW4YC_MxFUqesCv34uC11ds6lBunPSDCb8fniMoCgcnswJFl4n42rrcRaxmauQnAD8bL7FpyOdgCmQtuLcif249AcSKSmw2XVJNdNYps3dVA-3wYILdCI9x6VgUIWk3qoH-2Lf01Rfw0MGRHHMd0Gal9RbfXfRaGfK2H7kLR0mrt3y_R18QixM_R4PvltNuOeO0YYh7L508GSDxc-BSvQOoHyTFDQ8hiG-4UOMR0eD9enXa7EGwYPOJw";
      // setIsLoggedIn(isTokenNotVisitor);
      // if (!isTokenNotVisitor) {
      //   localStorage.removeItem("isVisitor");
      //   window.location.assign("/");
      // }

      // if (isTokenNotVisitor) {
      var token_ = props.token.Authorization;

      const parts = token_.split(".");
      if (parts.length !== 3) {
        throw new Error("Invalid JWT token");
      }

      const [header, payload] = parts;
      const decodedPayload = JSON.parse(atob(payload));
      console.log("decoded payload: ", decodedPayload["cognito:username"]);
      setUserName(decodedPayload["cognito:username"]);

      var url = baseURL + "user/" + decodedPayload["cognito:username"];
      console.log("asdasd:", url);

      const headers = {
        Authorization: token_,
      };
      axios
        .get(url, {
          headers,
        })
        .then((response) => {
          console.log("data:", response.data);
          setUserData(response.data.user);
          setDataRetrieved(true);
        })
        .catch((error) => {
          console.error(error);
        });
      // }
    }
  }, []);

  return isLoggedIn ? (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={headerContainer}>
        <Header />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}></p>
      </div>

      {/* <p
        style={{
          fontFamily: "Nunito",
          fontWeight: 700,
          fontSize: "20px",
        }}
      >
        Profil
      </p> */}
      <div>
        {dataRetrieved ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={card}>
              <div style={profilePicture}>
                <RiUserFill style={pp} size={70} />
              </div>

              <div
                style={{
                  fontSize: "22px",
                  color: "white",
                  fontWeight: "600",
                  margin: "10px",
                }}
              >
                {userName}
              </div>
              <div
                style={{
                  marginTop: "10px",
                  borderBottom: "1px solid white",
                  width: "300px",
                }}
              ></div>

              {/* mealLikeCount: "1",
    mealDislikeCount: "0",
    joinDate: "2023-07-20",
    lastOperationTime: "2023-07-21T12:00:17", */}

              <div style={row}>
                <h5>Gurme Skorun </h5>
                <p style={{ marginTop: "-10px" }}>{userData.gurmeScore}</p>
              </div>

              <div style={row}>
                <h5>Yaptığın Yorum Sayısı </h5>
                <p style={{ marginTop: "-10px" }}>{userData.commentCount}</p>
              </div>

              <div style={row}>
                <h5>Puanladığın Yemek Sayısı </h5>
                <p style={{ marginTop: "-10px" }}>{userData.foodRateCount}</p>
              </div>

              <div style={row}>
                <h5>Verdiğin Ortalama Puan </h5>
                <p style={{ marginTop: "-10px" }}>{userData.foodRateAverage}</p>
              </div>

              <div style={row}>
                <h5>Katıldığın Tarih </h5>
                <p style={{ marginTop: "-10px" }}>{userData.joinDate}</p>
              </div>
              <p> </p>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              style={{
                width: "80px",
                animation: `spin ${3}s linear infinite`,
              }}
              src={logo}
              alt="Logo"
            />
          </div>
        )}
      </div>
      <div
        onClick={() => {
          // Deleting token
          handleLogout();
        }}
        style={{
          // fontFamily: "Ubuntu",
          fontSize: "14px",
          marginTop: "50px",
          // marginBottom: "100px",
          paddingBottom: "15px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        Çıkış yap
      </div>
      <div style={bottomBlank}></div>
      <AboutUs />
      <div style={bottomBlank}></div>
      <div>
        <Footer page="profile" />
      </div>
    </div>
  ) : (
    <div
      style={{
        textAlign: "center",
      }}
    >
      <div style={headerContainer}>
        <Header />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}></p>
      </div>

      <div>
        <Footer page="profile" />
      </div>
    </div>
  );
}

// STYLES

const profilePicture = {
  width: "128px",
  height: "128px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "-64px",
  border: "rgb(132, 61, 61) 4px solid",
};

const pp = {
  color: "rgb(132, 61, 61)",
};

const row = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#8c4343",
  width: "300px",
  margin: "10px",
  borderRadius: "4px",
};

const card = {
  backgroundColor: "rgb(132, 61, 61)",
  width: "340px",
  marginTop: "80px",
  borderRadius: "20px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
};

const bottomBlank = {
  height: "100px",
};

const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};
