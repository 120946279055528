import React, { useState, useContext } from "react";
import axios from "axios";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";

import URLContext from "./URLContext";

export default function SingleCommentForFood({
  comment,
  token,
  showLikeButtons,
}) {
  const [isLiked, setIsLiked] = useState(comment.isLiked);
  const [isDisliked, setIsDisliked] = useState(comment.isDisliked);
  const [score, setScore] = useState(comment.score);

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  };

  String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
      return letters[letter];
    });
    return string.toLowerCase();
  };

  const convertToCamelCase = (str) => {
    const arr = str.turkishToLower().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).turkishToUpper() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");
    return str2;
  };

  const cropName = (commenter_name, food_name, max_len) => {
    if (commenter_name.length + food_name.length > max_len) {
      return food_name.substring(0, max_len - commenter_name.length) + "..";
    }
    return food_name;
  };

  // var token_ = token.Authorization;
  var initialScore = parseInt(comment.score);
  console.log(initialScore);
  if (comment.isLiked) {
    initialScore = initialScore - 1;
  } else if (comment.isDisliked) {
    initialScore = initialScore + 1;
  }

  var baseURL = useContext(URLContext);

  const commentOperation = (operation) => {
    if (showLikeButtons) {
      console.log("liking comment or whatever");
      console.log(comment);
      var url = baseURL + "/discovery-comment-vote";

      const headers = token;
      axios
        .post(
          url,
          {
            commentId: comment.commentId,
            operation,
            commenter: comment.commenter,
          },
          {
            headers,
          }
        )
        .then(() => {});
    } else {
      localStorage.removeItem("isVisitor");
      window.location.assign("/");
    }
  };

  return (
    <div key={comment.id} style={singleCommentContainer}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "8",
          backgroundColor: "#843d3d",
          borderRadius: "10px",
          padding: "2px",
          // borderBottomLeftRadius: "0px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            // alignItems: "start",
            flexDirection: "column",
            height: "60px",
            borderBottom: "1px inset rgba(255, 255, 255, 0.2)",
            marginLeft: "10px",
          }}
        >
          <div
            style={{
              fontFamily: "Nunito",
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.3)",
              marginLeft: "10px",
              // padding: "2px",
            }}
          >
            <p
              style={{
                height: "0px",
              }}
            >
              {" "}
              <span style={{ fontWeight: "800" }}>
                {comment.commenter}
              </span>{" "}
              yorumladı:{" "}
              <span style={{ fontWeight: "800", position: "relative" }}>
                {cropName(
                  comment.commenter,
                  convertToCamelCase(comment.to),
                  29
                )}
              </span>
            </p>
          </div>
          <div
            style={{
              // fontFamily: "Ubuntu",
              fontSize: "12px",
              color: "rgba(255, 255, 255, 0.3)",
              marginLeft: "10px",
            }}
          >
            <p style={{ height: "0px", display: "flex", alignItems: "center" }}>
              gurme puanı: {comment.gurmeScore}
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            // borderBottom: "1px inset white",
          }}
        >
          {" "}
          <p
            style={{
              fontFamily: "Nunito",
              fontSize: "14px",
              color: "white",
              marginLeft: "20px",
            }}
          >
            {comment.text}
          </p>
        </div>

        {comment.imageUrl.length > 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderTop: "2px rgba(255, 255, 255, 0.3) solid",
            }}
          >
            <a
              href={comment.imageUrl}
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: "flex" }}
            >
              <img
                src={comment.imageUrl}
                style={{
                  borderRadius: "10px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  // border: "1px white solid",
                  width: "100%",
                }}
              />
            </a>
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          //backgroundColor: "black",
          flex: "1",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "95px",
        }}
      >
        {isLiked ? (
          <AiFillLike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("unlike");

              setScore(initialScore);
              setIsLiked(false);
            }}
            size="48"
          />
        ) : (
          <AiOutlineLike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("like");

              if (showLikeButtons) {
                setScore(initialScore + 1);
                setIsLiked(true);
                setIsDisliked(false);
              }
            }}
            size="48"
          />
        )}

        <p style={{ fontFamily: "Ubuntu", fontSize: "14px", color: "#843d3d" }}>
          {score}
        </p>
        {isDisliked ? (
          <AiFillDislike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("undislike");

              setScore(initialScore);
              setIsDisliked(false);
            }}
            size="48"
          />
        ) : (
          <AiOutlineDislike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("dislike");

              if (showLikeButtons) {
                setScore(initialScore - 1);
                setIsLiked(false);
                setIsDisliked(true);
              }
            }}
            size="48"
          />
        )}
      </div>
    </div>
  );
}

// STYLES
const singleCommentContainer = {
  // backgroundColor: "#753838",
  display: "flex",
  width: "100%",
  borderRadius: "4px",
  marginTop: "15px",
  alignItems: "center",
};
