import React, { useContext, useState, useEffect } from "react";
import axios from "axios";

import SingleComment from "./SingleComment";
import { ImStarFull, ImStarEmpty } from "react-icons/im";
import { AiFillCamera, AiOutlineArrowUp } from "react-icons/ai";
import { TiTick } from "react-icons/ti";

import { RiSendPlaneLine, RiSendPlaneFill } from "react-icons/ri";

import { Link } from "react-router-dom";
import URLContext from "./URLContext";
import "./Animation.css";
import S3FileUpload from "react-s3";
import Resizer from "react-image-file-resizer";
import { Puff } from "react-loading-icons";
import bucket_exports from "./bucket_exports";
import ReactGA from "react-ga4";

window.Buffer = window.Buffer || require("buffer").Buffer;

const S3_BUCKET = bucket_exports.REACT_APP_BUCKET_NAME;
const REGION = bucket_exports.REACT_APP_REGION;
const ACCESS_KEY = bucket_exports.REACT_APP_ACCESS;
const SECRET_ACCESS_KEY = bucket_exports.REACT_APP_SECRET;

const config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  dirName: "images",
};

export default function Food({
  name,
  score,
  comment_count,
  token,
  userVote,
  userComment,
  voteCount,
  isLoggedIn,
  username,
  topComments,
}) {
  const [vote, setVote] = useState(userVote);
  const [stars, setStars] = useState([]);
  const [comment, setComment] = useState(userComment);
  const [isVoted, setIsVoted] = useState(vote !== 0);
  const [commentsExpanded, setCommentsExpanded] = useState(true);
  const [isSendingComment, setIsSendingComment] = useState(false);
  const [isCommented, setIsCommented] = useState(userComment !== "");
  const [isJustCommented, setIsJustCommented] = useState(false);
  const [commentsOfFood, setCommentsOfFood] = useState(topComments);

  const [commentsRetrieved, setCommentsRetrieved] = useState(true);
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const [hasUploadedImage, setHasUploadedImage] = useState(false);
  const [hasUploadedImageError, setHasUploadedImageError] = useState(false);
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [canComment, setCanComment] = useState(false);
  const [sawAllComments, setSawAllComments] = useState(false);
  const [gettingAdditionalComments, setGettingAdditionalComments] =
    useState(false);

  const baseURL = useContext(URLContext);

  // var token_ = token.Authorization;

  const hiddenFileInput = React.useRef(null);

  const makeid = (length) => {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileInput = async (e) => {
    setCanComment(false);
    setIsUploadingImage(true);

    let ext =
      e.target.files[0].name.split(".")[
        e.target.files[0].name.split(".").length - 1
      ];
    let new_name = username + "_" + makeid(10) + "." + ext;

    const file = new File(e.target.files, new_name);

    try {
      Resizer.imageFileResizer(
        file,
        1080,
        1080,
        "JPEG",
        100,
        0,
        (uri) => {
          S3FileUpload.uploadFile(uri, config)
            .then((response) => {
              console.log(response);
              setIsUploadingImage(false);
              setHasUploadedImage(true);
              setUploadedImageUrl(response.location);
              setCanComment(true);
            })
            .catch((err) => {
              setHasUploadedImageError(true);
            });
        },
        "file"
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = async (file) => {
    S3FileUpload.uploadFile(file, config)
      .then((response) => console.log(response))
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    const newStars = [];
    for (let i = 0; i < vote; i++) {
      newStars.push(
        <ImStarFull
          style={{
            cursor: "pointer",
          }}
          size={24}
          key={i}
        />
      );
    }
    setStars(newStars);
  }, [vote]);

  useEffect(() => {
    if (topComments.length < 3) {
      setSawAllComments(true);
    }
    console.log(topComments);
  }, []);

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  };

  return (
    <div
      style={
        window.innerWidth <= 768 ? foodContainerStyle : foodContainerStyleWeb
      }
    >
      <div style={headerAndScoreContainer}>
        <Link
          style={{ color: "white", textDecoration: "none" }}
          to={{ pathname: "/foods/" + name, state: { score, voteCount } }}
        >
          <p style={headerStyle}>{name}</p>
        </Link>
        <div style={scoreContainerStyle}>
          <p style={scoreStyle}>{score}</p>
          <ImStarFull style={{ marginBottom: "4px" }} />
        </div>
      </div>
      {!isVoted ? (
        <div style={rateContainer}>
          <div
            onClick={() => {
              if (isLoggedIn) {
                setVote(1);
              } else {
                localStorage.removeItem("isVisitor");
                window.location.reload();
              }
            }}
          >
            {vote < 1 ? <Star isEmpty={true} /> : <Star isEmpty={false} />}
          </div>

          <div
            onClick={() => {
              if (isLoggedIn) {
                setVote(2);
              } else {
                localStorage.removeItem("isVisitor");
                window.location.reload();
              }
            }}
          >
            {vote < 2 ? <Star isEmpty={true} /> : <Star isEmpty={false} />}
          </div>

          <div
            onClick={() => {
              if (isLoggedIn) {
                setVote(3);
              } else {
                localStorage.removeItem("isVisitor");
                window.location.reload();
              }
            }}
          >
            {vote < 3 ? <Star isEmpty={true} /> : <Star isEmpty={false} />}
          </div>

          <div
            onClick={() => {
              if (isLoggedIn) {
                setVote(4);
              } else {
                localStorage.removeItem("isVisitor");
                window.location.reload();
              }
            }}
          >
            {vote < 4 ? <Star isEmpty={true} /> : <Star isEmpty={false} />}
          </div>

          <div
            onClick={() => {
              if (isLoggedIn) {
                setVote(5);
              } else {
                localStorage.removeItem("isVisitor");
                window.location.reload();
              }
            }}
          >
            {vote < 5 ? <Star isEmpty={true} /> : <Star isEmpty={false} />}
          </div>
        </div>
      ) : null}
      {!isVoted ? (
        vote === 0 ? (
          <div style={oylaContainerStyleDisabled}>
            <p style={{ fontFamily: "Nunito" }}>Oyla</p>
          </div>
        ) : (
          <div
            onClick={() => {
              var url = baseURL + "/vote";
              setIsVoted(true);
              const headers = token;
              console.log("headers:", headers);
              axios
                .post(
                  url,
                  { foodName: name.turkishToUpper(), score: vote },
                  {
                    headers,
                  }
                )
                .then(() => {});
            }}
            style={oylaContainerStyle}
          >
            <p style={{ fontFamily: "Nunito" }}>Oyla</p>
          </div>
        )
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            height: "120px",
          }}
        >
          <div
            style={{
              fontFamily: "Nunito",
              fontSize: "24px",
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                fontFamily: "Nunito",
                fontSize: "16px",
                textAlign: "center",
                marginBottom: "10px",
              }}
            >
              Teşekkürler!
            </div>
            <div
              onClick={() => {
                setIsVoted(false);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {stars}
            </div>
          </div>
        </div>
      )}

      {
        !commentsExpanded ? (
          <div
            onClick={() => {
              var url = baseURL + "/get-food-comments";

              setCommentsExpanded(true);
              const headers = token;
              axios
                .post(
                  url,
                  { foodName: name.turkishToUpper() },
                  {
                    headers,
                  }
                )
                .then((response) => {
                  console.log(response.data);
                  setCommentsOfFood(response.data);
                  setCommentsRetrieved(true);
                });
            }}
            style={seeCommentsContainer}
          >
            <p
              style={{
                textDecoration: "underline",
                // fontFamily: "Ubuntu",
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              Yorumlar ({comment_count})
            </p>
          </div>
        ) : (
          <p></p>
        )
        // <div
        //   onClick={() => setCommentsExpanded(false)}
        //   style={seeCommentsContainer}
        // >

        //   <AiOutlineArrowUp
        //     style={{
        //       cursor: "pointer",
        //       margin: "10px",
        //       color: "rgba(255, 255, 255, 0.3",
        //     }}
        //   />
        // </div>
      }

      {!commentsExpanded ? null : (
        <div style={commentsContainerStyle}>
          {
            isSendingComment ? (
              // <p
              //   style={{
              //     marginTop: "28px",
              //     fontSize: "14px",
              //     color: "rgba(255, 255, 255, 0.3",
              //   }}
              // >
              //   Yorum yapılıyor...
              // </p>

              <Puff
                style={{ marginTop: "12px" }}
                stroke="white"
                strokeOpacity={1}
                speed={0.75}
              />
            ) : !isCommented ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    // backgroundColor: "red",
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <textarea
                    onChange={(e) => {
                      setComment(e.target.value);
                      setCanComment(e.target.value !== "");
                    }}
                    placeholder="Yorum yap..."
                    value={comment}
                    style={{
                      borderWidth: "0px",
                      backgroundColor: "#8c4646",
                      color: "white",
                      opacity: "1",
                      marginTop: "20px",
                      height: "60px",
                      width: "90%",
                      // fontFamily: "Ubuntu",
                      fontSize: "14px",
                      paddingLeft: "20px",
                      paddingTop: "10px",
                    }}
                  />

                  {!canComment ? (
                    <RiSendPlaneLine
                      size="26"
                      style={{
                        margin: "10px",
                        marginTop: "30px",
                      }}
                    />
                  ) : (
                    <RiSendPlaneFill
                      onClick={() => {
                        if (isLoggedIn) {
                          setIsSendingComment(true);
                          var url = baseURL + "/comment";

                          const headers = token;
                          axios
                            .post(
                              url,
                              {
                                text: comment,
                                foodName: name.turkishToUpper(),
                                imageUrl: uploadedImageUrl,
                              },
                              {
                                headers,
                              }
                            )
                            .then(() => {
                              console.log("commenting");
                              setIsSendingComment(false);
                              setIsCommented(true);
                              setIsJustCommented(true);
                            })
                            .catch((error) => {
                              console.error(error);
                            });
                        } else {
                          localStorage.removeItem("isVisitor");
                          window.location.reload();
                        }
                      }}
                      size="26"
                      style={{
                        cursor: "pointer",
                        margin: "10px",
                        marginTop: "30px",
                      }}
                    />
                  )}
                </div>
                {isUploadingImage ? (
                  // <p
                  //   style={{
                  //     fontSize: "14px",
                  //     color: "rgba(255, 255, 255, 0.3)",
                  //   }}
                  // >
                  //   Fotoğraf Yükleniyor...
                  // </p>

                  <Puff
                    style={{ marginTop: "12px" }}
                    stroke="white"
                    strokeOpacity={1}
                    speed={0.75}
                  />
                ) : hasUploadedImageError ? (
                  <p
                    style={{
                      fontSize: "14px",
                      color: "rgba(255, 255, 255, 0.3)",
                    }}
                  >
                    Hata!
                  </p>
                ) : hasUploadedImage ? (
                  <TiTick
                    size="26"
                    style={{
                      margin: "4px",
                      color: "rgba(255, 255, 255, 0.6)",
                    }}
                  />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      cursor: "pointer",
                      justifyContent: "center",
                      // backgroundColor: "red",
                      // width: "100%",
                    }}
                    onClick={() => {
                      if (isLoggedIn) {
                        handleClick();
                      } else {
                        localStorage.removeItem("isVisitor");
                        window.location.reload();
                      }
                    }}
                  >
                    <input
                      ref={hiddenFileInput}
                      type="file"
                      accept="image/*"
                      style={{ display: "none" }}
                      onChange={handleFileInput}
                    />
                    <AiFillCamera
                      size="26"
                      style={{
                        margin: "4px",
                        color: "rgba(255, 255, 255, 0.6)",
                      }}
                    />
                    <p
                      style={{
                        fontSize: "12px",
                        color: "rgba(255, 255, 255, 0.6)",
                      }}
                    >
                      Fotoğraf Yükle
                    </p>
                  </div>
                )}
              </div>
            ) : isJustCommented ? (
              <p
                style={{
                  marginTop: "28px",
                  fontSize: "14px",
                  color: "rgba(255, 255, 255, 0.3",
                }}
              >
                Yorum yapıldı!
              </p>
            ) : null
            // (
            //   <div
            //     style={{
            //       display: "flex",
            //       alignItems: "center",
            //       justifyContent: "center",
            //       flexDirection: "column",
            //       height: "60px",
            //     }}
            //   >
            //     <div
            //       style={{
            //         // fontFamily: "Ephesis",
            //         flexDirection: "column",
            //         fontSize: "24px",
            //         marginTop: "20px",
            //         display: "flex",
            //       }}
            //     >
            //       <div
            //         onClick={() => {
            //           setIsCommented(false);
            //         }}
            //         style={{
            //           marginLeft: "10px",
            //           fontSize: "12px",
            //           // fontFamily: "Ubuntu",
            //           textDecoration: "underline",
            //           cursor: "pointer",
            //         }}
            //       >
            //         <AiOutlineEdit size={22} />
            //       </div>
            //     </div>
            //   </div>
            // )
          }

          {commentsRetrieved ? (
            commentsOfFood.map((comment) => (
              <SingleComment
                key={comment.commentId}
                token={token}
                comment={comment}
                username={username}
                isLoggedIn={isLoggedIn}
              />
            ))
          ) : (
            <div
              style={{
                // marginTop: "50px",
                fontSize: "14px",
                height: "70px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // fontFamily: "Ubuntu",
              }}
            >
              Yorumlar yükleniyor...
            </div>
          )}

          {!sawAllComments ? (
            gettingAdditionalComments ? (
              <Puff
                style={{ marginTop: "12px" }}
                stroke="white"
                strokeOpacity={1}
                speed={0.75}
              />
            ) : (
              <p
                style={{
                  textDecorationColor: "white",
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontSize: "14px",
                  color: "white",
                }}
                onClick={() => {
                  ReactGA.event({
                    category: name.turkishToUpper(),
                    action: "more_comments",
                  });
                  setGettingAdditionalComments(true);
                  var url = baseURL + "/get-food-comments";

                  setCommentsExpanded(true);
                  const headers = token;
                  axios
                    .post(
                      url,
                      {
                        foodName: name.turkishToUpper(),
                        lastEvaluatedId:
                          commentsOfFood[commentsOfFood.length - 1][
                            "commentId"
                          ],
                      },
                      {
                        headers,
                      }
                    )
                    .then((response) => {
                      if (response.data.length < 10) {
                        setSawAllComments(true);
                      }
                      setGettingAdditionalComments(true);
                      console.log(response.data);
                      setCommentsOfFood([...commentsOfFood, ...response.data]);
                      setCommentsRetrieved(true);
                    });
                }}
              >
                {" "}
                Daha fazla yorum
              </p>
            )
          ) : (
            <Link
              style={{ textDecorationColor: "white" }}
              to={{ pathname: "/foods/" + name, state: { score, voteCount } }}
            >
              <p
                style={{
                  // fontFamily: "Ubuntu",
                  fontSize: "14px",
                  color: "white",
                }}
              >
                {" "}
                Yemek sayfasına git
              </p>
            </Link>
          )}
        </div>
      )}
    </div>
  );
}

const Star = ({ isEmpty }) => {
  return (
    <div>
      {isEmpty ? (
        <ImStarEmpty style={{ cursor: "pointer" }} size={32} />
      ) : (
        <ImStarFull style={{ cursor: "pointer" }} size={32} />
      )}
    </div>
  );
};

// STYLES
const foodContainerStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  // height: "300px",
  width: "90%",
  backgroundColor: "#843d3d",
  color: "white",
  marginTop: "10px",
  marginBottom: "10px",
  padding: "10px",
  borderRadius: "12px",
};

const foodContainerStyleWeb = { ...foodContainerStyle, width: "300px" };

const headerStyle = {
  paddingLeft: "2px",
  // fontFamily: "Ubuntu",
  fontSize: "16px",
  fontWeight: "500",
  // textDecoration: "underline",
};

const scoreStyle = {
  // fontFamily: "Ubuntu",
  fontSize: "16px",
  paddingRight: "4px",
};

const headerAndScoreContainer = {
  display: "flex",
  height: "20%",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  // backgroundColor: "black",
  borderBottom: "1px inset white",
};

const scoreContainerStyle = {
  height: "100%",
  // backgroundColor: "red",
  width: "20%",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
};

const rateContainer = {
  height: "20%",
  // backgroundColor: "red",
  width: "80%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "25px",
  marginBottom: "15px",
  //marginBottom: "8%",
};

const seeCommentsContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "25%",
  // backgroundColor: "yellow",
  width: "100%",
};

const oylaContainerStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20%",
  width: "90%",
  backgroundColor: "#402727",
  borderRadius: "8px",
  marginTop: "10px",
  marginBottom: "10px",
  cursor: "pointer",
};

const oylaContainerStyleDisabled = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "20%",
  width: "90%",
  backgroundColor: "#5c3232",
  borderRadius: "8px",
  marginTop: "10px",
  marginBottom: "10px",
};

const commentsContainerStyle = {
  borderTop: "1px outset white",
  flexDirection: "column",
  width: "100%",
  display: "flex",
  justifycontent: "center",
  alignItems: "center",
};

const commentButtonStyle = {
  marginTop: "10px",
  width: "50%",
  height: "20%",
  backgroundColor: "#402727",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
};

const commentButtonStyleDisabled = {
  marginTop: "10px",
  width: "50%",
  height: "20%",
  backgroundColor: "#5c3232",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "8px",
};

const singleCommentContainer = {
  backgroundColor: "#753838",
  display: "flex",
  width: "100%",
  borderRadius: "4px",
  marginTop: "15px",
  alignItems: "center",
};
