import React, { useEffect, useState, useContext } from "react";
import Header from "./Header";
import { TextInput } from "react-native";
import { Auth } from "aws-amplify";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import axios from "axios";
import ReactGA from "react-ga4";

import "./Animation.css";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

export default function ForgotPasswordPage(props) {
  const [mail, setMail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [sentCode, setSentcode] = useState(false);
  const [canSubmit, setCanSubmit] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "page view",
    });
  }, []);

  const handleSendCode = async () => {
    const data = await Auth.forgotPassword(mail)
      .then((response) => {
        setSentcode(true);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleSetNewPassword = async () => {
    setError(false);
    Auth.forgotPasswordSubmit(mail, code, newPassword)
      .then((response) => {
        if (response == "SUCCESS") {
          window.location.assign("/");
        } else {
          setError(true);
          setErrorText("Hata!");
        }
        console.log(response);
      })
      .catch((error) => {
        if (error.code == "CodeMismatchException") {
          setErrorText("Hatalı kod");
        } else if (error.code == "InvalidPasswordException") {
          setErrorText("Şifre daha uzun olmalı");
        } else {
          setErrorText("Hata!");
        }
        setError(true);
        console.error(error);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={headerContainer}>
        <Header />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}></p>
      </div>
      <p
        style={{
          // fontFamily: "Ubuntu",
          marginTop: "40px",
          color: "#F44336",
        }}
      >
        {errorText}
      </p>

      <p
        style={{
          // fontFamily: "Ubuntu",
          fontWeight: "bold",
          fontSize: "20px",
          align: "center",
          marginTop: "20px",
        }}
      >
        Şifremi Unuttum
      </p>
      {sentCode ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <TextInput
            style={{
              height: "40px",
              width: "200px",
              borderBottomWidth: 1,
              borderColor: "gray",
              borderRadius: 0,
              textAlign: "center",
            }}
            placeholder="e-mail adresi"
            placeholderTextColor="gray"
            type="text"
          />
          <TextInput
            style={{
              height: "40px",
              width: "200px",
              borderBottomWidth: 1,
              borderColor: "gray",
              borderRadius: 0,
              textAlign: "center",
            }}
            placeholder="maile iletilen kod"
            placeholderTextColor="gray"
            type="text"
            onChange={(e) => {
              setCode(e.target.value);
            }}
          />
          <TextInput
            style={{
              height: "40px",
              width: "200px",
              borderBottomWidth: 1,
              borderColor: "gray",
              borderRadius: 0,
              textAlign: "center",
            }}
            placeholder="yeni şifre"
            placeholderTextColor="gray"
            type="text"
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <div style={{ marginTop: "12px" }}>
            <button
              style={buttonStyle}
              type="submit"
              onClick={handleSetNewPassword}
            >
              Şifremi Yenile
            </button>
          </div>
        </div>
      ) : (
        <div>
          <TextInput
            style={{
              height: "40px",
              width: "200px",
              borderBottomWidth: 1,
              borderColor: "gray",
              borderRadius: 0,
              textAlign: "center",
            }}
            placeholder="e-mail adresi"
            placeholderTextColor="gray"
            type="text"
            onChange={(e) => {
              setCanSubmit(e.target.value.length > 0);
              setMail(e.target.value);
            }}
          />
          <div style={{ marginTop: "12px" }}>
            <button
              style={buttonStyle}
              type="submit"
              onClick={handleSendCode}
              disabled={!canSubmit}
            >
              Kod Gönder
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

// STYLES

const profilePicture = {
  width: "128px",
  height: "128px",
  borderRadius: "50%",
  overflow: "hidden",
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "-64px",
  border: "rgb(132, 61, 61) 4px solid",
};

const pp = {
  color: "rgb(132, 61, 61)",
};

const row = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "#8c4343",
  width: "300px",
  margin: "10px",
  borderRadius: "4px",
};

const card = {
  backgroundColor: "rgb(132, 61, 61)",
  width: "340px",
  marginTop: "80px",
  borderRadius: "20px",

  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  color: "white",
};

const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};

const buttonStyle = {
  backgroundColor: "#843d3d",
  width: "200px",
  height: "40px",
  borderRadius: "10px",
  border: "none",
  color: "#e3e1e1",
  fontSize: "14px",
  // fontFamily: "Ubuntu",
  fontWeight: "bold",
  cursor: "pointer",
};
