import React, { useState, useEffect } from "react";
import FoodPage from "./FoodPage";
import SignupPage from "./SignupPage_v2";
import DiscoverPage from "./DiscoverPage";
import SSPage from "./SSPage";
import ProfilePage from "./ProfilePage";
import ForgotPasswordPage from "./ForgotPasswordPage";
import AdminPage from "./AdminPage";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Main from "./Main";
import ReactGA from "react-ga4";

import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";
import awsExports from "./aws-exports";

import "@aws-amplify/ui-react/styles.css";

const TRACKING_ID = "G-8DNTD7M58X";
ReactGA.initialize(TRACKING_ID);

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

function saveToken(userToken) {
  localStorage.setItem("token", JSON.stringify(userToken));
}

// function getToken() {
//   const tokenString = localStorage.getItem("token");
//   const userToken = JSON.parse(tokenString);
//   return userToken ? userToken : null;
// }

function getIsVisitor() {
  const tokenString = localStorage.getItem("isVisitor");
  const userToken = JSON.parse(tokenString);
  return userToken ? userToken : null;
}

const is_visitor = getIsVisitor();
console.log("is visitor: ", is_visitor);

function App() {
  const [token, setToken] = useState(null);
  const [gotSessionInfo, setGotSessionInfo] = useState(false);

  if (window.location.protocol === "http:") {
    window.location.href =
      "https://" + window.location.host + window.location.pathname;
  }

  useEffect(() => {
    if (!is_visitor) {
      Auth.currentSession()
        .then((response) => {
          setToken({ Authorization: response.idToken.jwtToken });
          setGotSessionInfo(true);
        })
        .catch((error) => {
          setGotSessionInfo(true);
          setToken(null);
        });
    }
  }, []);

  if (is_visitor) {
    return (
      <Router>
        <Switch>
          <Route path="/" exact>
            <Main token={null} />
          </Route>
          <Route path="/foods/:foodName">
            <FoodPage token={null} />
          </Route>
          <Route path="/discover/">
            <DiscoverPage token={null} />
          </Route>
          <Route path="/profile/">
            <ProfilePage token={null} />
          </Route>
        </Switch>
      </Router>
    );
  } else {
    return gotSessionInfo ? (
      token ? (
        <Router>
          <Switch>
            <Route path="/" exact>
              <Main token={token} />
            </Route>
            <Route path="/foods/:foodName">
              <FoodPage token={token} />
            </Route>
            <Route path="/discover/">
              <DiscoverPage token={token} />
            </Route>
            <Route path="/profile/">
              <ProfilePage token={token} />
            </Route>
            <Route path="/admin/">
              <AdminPage token={token} />
            </Route>
            <Route path="/ss/">
              <SSPage token={token} />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <Switch>
            <Route path="/" exact>
              <SignupPage setToken={setToken} saveToken={saveToken} />
            </Route>
            <Route path="/forgot_password">
              <ForgotPasswordPage />
            </Route>
          </Switch>
        </Router>
      )
    ) : (
      <div>Yükleniyor</div>
    );
  }
}

export default App;
