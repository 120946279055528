import React, { useEffect, useState, useContext } from "react";
import "./Animation.css";

import Food from "./Food";
import Header from "./Header";
import Footer from "./Footer";
import AboutUs from "./AboutUs";
import Gurme from "./Gurme";
import axios from "axios";
import logo from "./images/logo_to_spin.png";
import ReactGA from "react-ga4";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";

import { FaCrown } from "react-icons/fa";

import URLContext from "./URLContext";

export default function Main({ token }) {
  const [foods, setFoods] = useState([]);
  const [gotLunchInfo, setGotLunchInfo] = useState(false);
  const [isLunch, setIsLunch] = useState(true);
  const [noFood, setNoFood] = useState(false);
  const [likeCount, setLikeCount] = useState();
  const [dislikeCount, setDislikeCount] = useState();
  const [initLikeCount, setInitLikeCount] = useState();
  const [initDislikeCount, setInitDislikeCount] = useState();

  const [likedMeal, setLikedMeal] = useState(false);
  const [dislikedMeal, setDislikedMeal] = useState(false);
  const [username, setUsername] = useState();

  const [gurmes, setGurmes] = useState([]);
  const [usersGurmeScore, setUsersGurmeScore] = useState();
  const [usersRank, getUsersRank] = useState();

  const baseURL = useContext(URLContext);
  const [isLoggedIn, setIsLoggedIn] = useState(token != null);
  // const token_ = token.Authorization;

  const voteMeal = (isLiked) => {
    var url = baseURL + "/meal-vote";
    const headers = token;

    axios.post(url, { isLunch, isLiked }, { headers }).then(() => {});
  };

  String.prototype.turkishToLower = function () {
    var string = this;
    var letters = { İ: "i", I: "ı", Ş: "ş", Ğ: "ğ", Ü: "ü", Ö: "ö", Ç: "ç" };
    string = string.replace(/(([İIŞĞÜÇÖ]))/g, function (letter) {
      return letters[letter];
    });
    return string.toLowerCase();
  };

  String.prototype.turkishToUpper = function () {
    var string = this;
    var letters = { i: "İ", ı: "I", ş: "Ş", ğ: "Ğ", ü: "Ü", ö: "Ö", ç: "Ç" };
    string = string.replace(/(([iışğüçö]))/g, function (letter) {
      return letters[letter];
    });
    return string.toUpperCase();
  };

  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname,
      title: "page view",
    });
    window.scrollTo({ top: 0, left: 0 });
    var url;
    if (token == null) {
      url = baseURL + "/main_page-visitor";
    } else {
      url = baseURL + "/main_page";
    }
    console.log(url);
    const headers = token;
    console.log(headers);
    axios
      .get(url, { headers })
      .then((response) => {
        // setIsLoggedIn("username" in response.data);
        console.log("is logged in: ", isLoggedIn);
        if ("username" in response.data) {
          setUsername(response.data.username);
        }

        var like_info = response.data.mealVote;
        var initLikes = parseInt(response.data.likeCount);
        var initDislikes = parseInt(response.data.dislikeCount);

        if (like_info === 1) {
          setDislikedMeal(true);
          initDislikes = initDislikes - 1;
        } else if (like_info === 2) {
          setLikedMeal(true);
          initLikes = initLikes - 1;
        }

        setLikeCount(parseInt(response.data.likeCount));
        setDislikeCount(parseInt(response.data.dislikeCount));

        setInitLikeCount(initLikes);
        setInitDislikeCount(initDislikes);
        setIsLunch(response.data.isLunch);
        setGotLunchInfo(true);
        setFoods(response.data.foods);
        setNoFood(response.data.foods.length == 0);
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            localStorage.removeItem("isVisitor");
            window.location.reload();
          }
        } else {
          console.error(error);
        }
      });

    if (token == null) {
      url = baseURL + "/get-gurmes-visitor";
    } else {
      url = baseURL + "/get-gurmes";
    }

    axios
      .get(url, { headers })
      .then((response) => {
        setGurmes(response.data.gurmes);
        setUsersGurmeScore(response.data.usersGurmeScore);
        getUsersRank(response.data.usersRank);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const convertToCamelCase = (str) => {
    const arr = str.turkishToLower().split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).turkishToUpper() + arr[i].slice(1);
    }

    const str2 = arr.join(" ");
    return str2;
  };
  return (
    <div style={mainContainer}>
      <div style={headerContainer}>
        <Header />
      </div>
      {noFood ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "120px",
              // fontFamily: "Ephesis",
              fontSize: "22px",
              fontWeight: 600,
            }}
          >
            Henüz yemek saatinde değiliz.
          </div>
          <div>
            <p>
              <span style={{ textDecoration: "underline" }}>
                <a style={{ color: "black" }} href="/discover">
                  Keşfet
                </a>
              </span>{" "}
              sayfasına göz atabilirsin.
            </p>
          </div>
        </div>
      ) : (
        <div>
          {gotLunchInfo ? (
            isLunch ? (
              <div style={menuTextContainerStyle}>
                <p style={menuTextStyle}> Öğle Menüsü</p>
              </div>
            ) : (
              <div style={menuTextContainerStyle}>
                <p style={menuTextStyle}> Akşam Menüsü</p>
              </div>
            )
          ) : (
            <div
              style={{
                marginTop: "150px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                style={{
                  width: "100px",
                  animation: `spin ${3}s linear infinite`,
                }}
                src={logo}
                alt="Logo"
              />
            </div>
          )}
        </div>
      )}
      <div style={container}>
        {gotLunchInfo && !noFood ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //height: "100px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignContent: "center",
                marginBottom: "10px",
                //background: "red",
              }}
            >
              {isLoggedIn ? (
                <div>
                  <div
                    style={{
                      fontSize: "15px",
                      // fontFamily: "Ubuntu",
                    }}
                  >
                    Menüyü tavsiye eder misin?
                  </div>
                  <div
                    style={{
                      width: "90%",
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                      marginTop: "20px",
                    }}
                  >
                    {likedMeal ? (
                      <AiFillLike style={{ color: "#843d3d" }} size="42" />
                    ) : (
                      <AiOutlineLike
                        style={{ color: "#843d3d", cursor: "pointer" }}
                        onClick={() => {
                          setLikedMeal(true);
                          setDislikedMeal(false);
                          setLikeCount(initLikeCount + 1);
                          setDislikeCount(initDislikeCount);
                          voteMeal(true);
                        }}
                        size="42"
                      />
                    )}
                    <div>{likeCount}</div>
                    {dislikedMeal ? (
                      <AiFillDislike style={{ color: "#843d3d" }} size="42" />
                    ) : (
                      <AiOutlineDislike
                        style={{ color: "#843d3d", cursor: "pointer" }}
                        onClick={() => {
                          setDislikedMeal(true);
                          setLikedMeal(false);
                          setDislikeCount(initDislikeCount + 1);
                          setLikeCount(initLikeCount);
                          voteMeal(false);
                        }}
                        size="42"
                      />
                    )}
                    <div>{dislikeCount}</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {foods.map((food) => (
          <Food
            key={food.name}
            name={convertToCamelCase(food.name)}
            score={food.score}
            comment_count={food.commentCount}
            token={token}
            userVote={food.userVote}
            userComment={food.userComment}
            voteCount={food.voteCount}
            isLoggedIn={isLoggedIn}
            username={username}
            topComments={food.topComments}
          />
        ))}
        {gotLunchInfo ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              //background: "red",
              //height: "100px",
            }}
          >
            <div
              style={{
                //marginTop: "400px",
                //background: "rgba(0, 0, 0, 0.04)",
                borderRadius: "5px",
                padding: "10px",
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaCrown />
                <FaCrown style={{ margin: "5px" }} />
                <FaCrown />
              </div>
              <div style={{ fontFamily: "ephesis", fontSize: "24px" }}>
                Gurmelerimiz
              </div>
              <div
                style={{
                  borderTop: "1px inset rgba(0, 0, 0, 0.5)",
                  borderBottom: "1px outset rgba(0, 0, 0, 0.5)",
                  width: "250px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "10px",
                  paddingTop: "10px",
                }}
              >
                {gurmes.map((gurme, index) => {
                  return (
                    <Gurme
                      key={gurme.username}
                      place={index + 1}
                      username={gurme.username}
                      gurmeScore={gurme.gurmeScore}
                    />
                  );
                })}
              </div>

              {isLoggedIn ? (
                <Gurme
                  place={usersRank}
                  username={username}
                  gurmeScore={usersGurmeScore}
                />
              ) : null}
            </div>
            {isLoggedIn ? null : (
              <div
                onClick={() => {
                  // Deleting token
                  localStorage.removeItem("isVisitor");
                  window.location.reload();
                }}
                style={{
                  // fontFamily: "Ubuntu",
                  fontSize: "14px",
                  marginTop: "50px",
                  marginBottom: "100px",
                  paddingBottom: "15px",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                Giriş Yap
              </div>
            )}

            <div style={bottomBlank}></div>
            <AboutUs />
            <div style={bottomBlank}></div>
          </div>
        ) : null}
      </div>
      <div>
        <Footer page="home" isLoggedIn={isLoggedIn} />
      </div>
    </div>
  );
}

// STYLES
const bottomBlank = {
  height: "100px",
};
const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  //  top: 0; left: 0; width: 100%
};

const container = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  //  top: 0; left: 0; width: 100%
};

const mainContainer = {
  // backgroundColor: "white",
  width: "100%",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "30px",
  marginTop: "80px",
  marginBottom: "10px",
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
