import React from "react";

export default function Gurme({ place, username, gurmeScore }) {
  return (
    <div
      style={{
        borderRadius: "2px",
        width: "180px",
        display: "flex",
        // fontFamily: "Ubuntu",
        flexDirection: "row",
        alignItems: "start",
        margin: "5px",
        padding: "8px",
      }}
    >
      <div style={{ marginRight: "10px" }}>{place}</div>
      <div style={{}}>
        <div style={{ fontSize: "14px", fontWeight: "bold" }}>{username}</div>
        <div style={{ fontSize: "12px" }}>gurme puanı: {gurmeScore}</div>
      </div>
    </div>
  );
}
