import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function AboutUs() {
  return (
    <div style={{ height: "100px", textAlign: "center" }}>
      <p
        style={{
          fontSize: "12px",
          fontWeight: "bold",
          color: "rgba(0, 0, 0, 0.6",
        }}
      >
        İletişim
      </p>

      <p style={{ fontSize: "12px", color: "rgba(0, 0, 0, 0.6" }}>
        admin@itugurme.com
      </p>

      <a
        style={{
          fontSize: "12px",
          color: "rgba(0, 0, 0, 0.6",
          cursor: "pointer",
          textDecoration: "none",
        }}
        href="https://twitter.com/itugurme"
        target="_blank"
        rel="noopener noreferrer"
      >
        twitter.com/itugurme
      </a>
    </div>
  );
}
