import React, { useEffect, useState, useContext } from "react";

import Header from "./Header";
import PropTypes from "prop-types";
import URLContext from "./URLContext";
import axios from "axios";
import logo from "./images/logo_sign_in.png";
import logoOpen from "./images/logo_sign_in_open.png";
import { TextInput, TouchableOpacity } from "react-native";
import { AiFillEye } from "react-icons/ai";
import awsExports from "./aws-exports";
import { Puff } from "react-loading-icons";
import AboutUs from "./AboutUs";

import { Amplify } from "aws-amplify";
import { Auth } from "aws-amplify";

import "@aws-amplify/ui-react/styles.css";

Amplify.configure({
  Auth: {
    region: awsExports.REGION,
    userPoolId: awsExports.USER_POOL_ID,
    userPoolWebClientId: awsExports.USER_POOL_APP_CLIENT_ID,
  },
});

// process.env.USER_POOL_APP_CLIENT_ID

export default function Signup({ setToken, saveToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [mail, setMail] = useState("");
  const [isSignup, setIsSignup] = useState(false);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState(
    "Hatalı kullanıcı adı veya şifre!"
  );

  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmittingCode, setIsSubmittingCode] = useState(false);

  // const buttonText = isSubmitting
  //   ? "Yükleniyor..."
  //   : isSignup
  //   ? "Hesap Oluştur"
  //   : "Giriş Yap";

  const baseURL = useContext(URLContext);

  const [verificationCode, setVerificationCode] = useState("");
  const [showVerification, setShowVerification] = useState(false);

  const [isLoginForm, setIsLoginForm] = useState(true);
  const [showResendVerificationButton, setShowResendVerificationButton] =
    useState(false);

  const handleToggleForm = () => {
    setIsLoginForm(!isLoginForm);
  };

  const [verificationCodeError, setVerificationCodeError] = useState(false);

  function setIsVisitor(is_visitor) {
    if (is_visitor) {
      localStorage.setItem("isVisitor", JSON.stringify(is_visitor));
    } else {
      localStorage.removeItem("isVisitor");
    }
  }

  const handleLogin = async () => {
    try {
      const resp = await Auth.signIn(username, password);
      const token = {
        Authorization: resp.signInUserSession["idToken"]["jwtToken"],
      };
      console.log(token);
      setToken(token);
      // saveToken(token);
      window.location.assign("/");
    } catch (error) {
      console.error(error.code);
      if (error.code == "UserNotConfirmedException") {
        setErrorText("E-mail onayı gerekiyor");
        setShowResendVerificationButton(true);
      }
      setError(true);
      setIsSubmitting(false);
      return false;
    }
  };

  const handleSignUp = async () => {
    try {
      await Auth.signUp({
        username,
        password,
        attributes: {
          email: mail,
        },
      });

      setShowVerification(true);
    } catch (error) {
      console.error("Error signing up:", error);
      if (error.code == "UsernameExistsException") {
        setErrorText("Bu kullanıcı adı kullanımda");
      } else {
        setErrorText(error.message.split("_start_")[1]);
      }

      setError(true);
      setIsSubmitting(false);
      return false;
    }
  };

  const handleVerify = async () => {
    try {
      setIsSubmitting(true);
      console.log("123123123");
      const resp = await Auth.confirmSignUp(username, verificationCode);
      console.log("verification resp:", resp);

      if (resp == "SUCCESS") {
        await handleLogin()
          .then(() => {})
          .catch((err) => {
            setErrorText("Bilinmeyen bir hata oluştu");
            setError(true);
          });
      } else {
        setIsSubmittingCode(false);
        setErrorText("Bilinmeyen bir hata oluştu");
        setError(true);
      }
    } catch (error) {
      setIsSubmittingCode(false);
      setVerificationCodeError(true);
      console.error("Error verifying code:", error);
    }
  };

  useEffect(() => {
    setIsVisitor(false);
    window.scrollTo({ top: 0, left: 0 });
  }, []);

  const loginUser = async (url, credentials) => {
    console.log("url:", url);
    console.log("credit:", credentials);

    try {
      // const response = await axios.post(url, JSON.stringify(credentials));
      const response = await axios.post(url, credentials);
      console.log("resp:", response);
      return response.data;
    } catch (err) {
      setErrorText(err.response.data.message);
      setError(true);
      setIsSubmitting(false);
      return false;
    }
  };

  var url;
  if (isSignup) {
    url = baseURL + "/signup";
  } else {
    url = baseURL + "/signin";
  }

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    setError(false);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    e.preventDefault();
    if (String(username).trim().length == 0) {
      console.log("username error");
      setErrorText("Kullanıcı adı zorunludur.");
      setError(true);
      setIsSubmitting(false);
    } else if (String(password).trim().length < 8) {
      setErrorText("Şifre en az 8 karakterden oluşmalıdır.");
      setError(true);
      setIsSubmitting(false);
    } else if (isSignup && String(mail).trim().length == 0) {
      setErrorText("Mail zorunludur.");
      setError(true);
      setIsSubmitting(false);
    } else if (isSignup && !String(mail).includes("@itu.edu.tr")) {
      setErrorText("İTÜ uzantılı mail adresi zorunludur.");
      setError(true);
      setIsSubmitting(false);
    } else {
      var token = null;
      if (isSignup) {
        await handleSignUp()
          .then((response) => {
            token = response;
          })
          .catch(() => {});
      } else {
        console.log("logining");
        await handleLogin()
          .then((response) => {
            token = response;
          })
          .catch((err) => {
            console.error(err);
          });
      }
      console.log(token);
      // if (token) {
      //   console.log("saving token");

      //   setToken(token);
      //   saveToken(token);
      // }
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={headerContainer}>
        <Header clickable={false} />
      </div>
      <div style={menuTextContainerStyle}>
        <p style={menuTextStyle}>itügurme</p>
      </div>

      {showVerification && (
        <div style={{ textAlign: "center" }}>
          <p></p>
          {verificationCodeError && (
            <p
              style={{
                // fontFamily: "Ubuntu",
                marginTop: "40px",
                color: "#F44336",
              }}
            >
              Hatalı kod!
            </p>
          )}
          <TextInput
            style={{
              height: "40px",
              width: "200px",
              borderBottomWidth: 1,
              borderColor: "gray",
              borderRadius: 0,
              textAlign: "center",
            }}
            placeholder="Onay kodu"
            placeholderTextColor="gray"
            type="text"
            onChange={(e) => setVerificationCode(e.target.value)}
          />
          <div style={{ marginTop: "12px" }}>
            <button
              style={buttonStyle}
              type="submit"
              onClick={() => {
                setIsSubmittingCode(true);
                handleVerify();
              }}
              disabled={isSubmittingCode}
            >
              {!isSubmittingCode ? (
                "Kodu Onayla"
              ) : (
                <div
                  style={{
                    // backgroundColor: "red",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Puff
                    style={{ width: 26, height: 26 }}
                    stroke="white"
                    strokeOpacity={1}
                    speed={0.75}
                  />
                </div>
              )}
            </button>
          </div>
          <div style={{ width: "340px" }}>
            <p style={{ fontSize: "14px", color: "rgba(0, 0, 0, 0.6" }}>
              (Kodun size ulaşması İTÜ mail sağlayıcısından kaynaklı olarak
              biraz zaman alabilir.)
            </p>
            <p></p>
          </div>
        </div>
      )}

      {error && (
        <p
          style={{
            // fontFamily: "Ubuntu",
            marginTop: "40px",
            color: "#F44336",
          }}
        >
          {errorText}
        </p>
      )}
      {showResendVerificationButton && (
        <p
          onClick={async () => {
            setErrorText("Onay kodu tekrar gönderiliyor..");
            await Auth.resendSignUp(username)
              .then((response) => {
                console.log(response.CodeDeliveryDetails["Destination"]);
                setErrorText(
                  "Onay kodu şu adrese gönderildi: " +
                    response.CodeDeliveryDetails["Destination"]
                );
                setShowVerification(true);
              })
              .catch((err) => {
                if (err.code == "LimitExceededException") {
                  setErrorText("Limit aşıldı. Daha sonra tekrar deneyin.");
                } else {
                  setErrorText(err.message);
                }
                console.error(err);
              });
          }}
          style={{
            textDecoration: "underline",
            cursor: "pointer",
            fontSize: "14px",
          }}
        >
          Onay Kodunu Yeniden Gönder
        </p>
      )}

      <div style={formContainer}>
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <label
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isSignup ? (
              <p
                style={{
                  // fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  fontSize: "20px",
                  align: "center",
                  marginTop: "20px",
                }}
              >
                Kayıt Ol
              </p>
            ) : (
              <p
                style={{
                  // fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  fontSize: "20px",
                  align: "center",
                  marginTop: "20px",
                }}
              >
                Giriş Yap
              </p>
            )}
            {isSignup ? (
              <img
                style={{
                  width: "200px",
                  marginTop: "0px",
                }}
                src={logoOpen}
                alt="LogoOpen"
              />
            ) : (
              <img
                style={{ width: "200px", marginTop: "0px" }}
                src={logo}
                alt="Logo"
              />
            )}

            <TextInput
              style={{
                height: "40px",
                width: "200px",
                borderBottomWidth: 1,
                borderColor: "gray",
                borderRadius: 0,
              }}
              placeholder={
                isSignup
                  ? "Kullanıcı adın"
                  : "Kullanıcı adın / İTÜ mail adresin"
              }
              placeholderTextColor="gray"
              maxLength={isSignup ? "15" : "150"}
              type="text"
              onChange={(e) => setUserName(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
            />

            <TextInput
              style={{
                height: "40px",
                width: "200px",
                borderBottomWidth: 1,
                borderColor: "gray",
                borderRadius: 0,
              }}
              placeholder="Şifren"
              placeholderTextColor="gray"
              maxLength="15"
              type="text"
              secureTextEntry={!showPassword}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(event) => {
                if (event.key === "Enter") {
                  handleSubmit(event);
                }
              }}
            />

            {isSignup && (
              <TextInput
                style={{
                  height: "40px",
                  width: "200px",
                  borderBottomWidth: 1,
                  borderColor: "gray",
                  borderRadius: 0,
                }}
                placeholder="İTÜ mail adresin"
                placeholderTextColor="gray"
                type="text"
                onChange={(e) => setMail(e.target.value)}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSubmit(event);
                  }
                }}
              />
            )}
          </label>
          <TouchableOpacity
            style={{ marginTop: "10px" }}
            onPress={toggleShowPassword}
          >
            <AiFillEye size={24} style={{ color: "gray" }} />
          </TouchableOpacity>
          <div style={{ marginTop: "12px" }}>
            <button
              style={buttonStyle}
              type="submit"
              onClick={(e) => {
                setIsSubmitting(true);
                handleSubmit(e);
              }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <div
                  style={{
                    // backgroundColor: "red",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Puff
                    style={{ width: 26, height: 26 }}
                    stroke="white"
                    strokeOpacity={1}
                    speed={0.75}
                  />
                </div>
              ) : isSignup ? (
                "Kayıt Ol"
              ) : (
                "Giriş Yap"
              )}
              {/* {buttonText} */}
            </button>
          </div>

          {isSignup ? (
            <p
              onClick={() => {
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                setError(false);
                setIsSignup(false);
              }}
              style={{
                color: "black",
                textDecorationColor: "black",
                //textDecoration: "underline",
                // fontFamily: "Ubuntu",
                fontSize: "14px",
                marginTop: "30px",
                cursor: "pointer",
              }}
            >
              Hesabın var mı? <u>Giriş yap</u>
            </p>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <p
                onClick={() => {
                  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                  setError(false);
                  setIsSignup(true);
                }}
                style={{
                  color: "black",
                  textDecorationColor: "black",
                  // textDecoration: "underline",
                  // fontFamily: "Ubuntu",
                  marginTop: "30px",
                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Hesabın mı yok? <u>Kayıt ol</u>
              </p>
              <a
                href="/forgot_password"
                style={{
                  color: "black",
                  textDecorationColor: "black",
                  textDecoration: "underline",

                  fontSize: "14px",
                  cursor: "pointer",
                }}
              >
                Şifremi unuttum
              </a>
              <p></p>
            </div>
          )}
        </form>
      </div>

      <p> </p>
      <a
        style={{
          color: "black",
          fontSize: "14px",
          cursor: "pointer",
          textAlign: "center",
          textDecoration: "underline",
        }}
        onClick={() => {
          setIsVisitor(true);
          window.location.assign("/");
          // setToken({
          //   Authorization:
          //     "eyJraWQiOiJvOTRSOWhCQ2dKTXJhcHVycVFqa0cxZURyWHh0bFFyYUt0OXA1S2p6WFdnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMzE0MzhmMi00MGIxLTcwOGUtOGNiNi04OGI3MjhlZGU4MDkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYTI4V24zbVN0IiwiY29nbml0bzp1c2VybmFtZSI6InZpc2l0b3IiLCJvcmlnaW5fanRpIjoiN2ZiY2YxZjctNWU2OS00NWFmLWIyZGUtY2VjZTZmNWYwNmE2IiwiYXVkIjoiMmtnbXY5dWFkbmZzNnFkZTY5cGQ3ZmJlMWwiLCJldmVudF9pZCI6Ijc4ZDZhYWZhLWMwYmQtNDMyNC1hNzU0LWI5YWMzYzBiYmU5MiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjkxNTk3MDcwLCJleHAiOjE2OTE2MDA2NzAsImlhdCI6MTY5MTU5NzA3MCwianRpIjoiYzQ3ZDY3OWEtYzI3OC00NzM4LWE0Y2EtNjE5MjE0ZWUzMDdiIiwiZW1haWwiOiJoYXJ1bnRya21uQGdtYWlsLmNvbSJ9.Vo_S6pFcmo44_wlfB0Zxx4rIMY4Ye8ii9eWoKBCiIdrFpoSAkfRpo09O5TBEin001NJbqoGmt0ueFgSjnrfglOhl4_AxHkSW4YC_MxFUqesCv34uC11ds6lBunPSDCb8fniMoCgcnswJFl4n42rrcRaxmauQnAD8bL7FpyOdgCmQtuLcif249AcSKSmw2XVJNdNYps3dVA-3wYILdCI9x6VgUIWk3qoH-2Lf01Rfw0MGRHHMd0Gal9RbfXfRaGfK2H7kLR0mrt3y_R18QixM_R4PvltNuOeO0YYh7L508GSDxc-BSvQOoHyTFDQ8hiG-4UOMR0eD9enXa7EGwYPOJw",
          // });
          // saveToken({
          //   Authorization:
          //     "eyJraWQiOiJvOTRSOWhCQ2dKTXJhcHVycVFqa0cxZURyWHh0bFFyYUt0OXA1S2p6WFdnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMzE0MzhmMi00MGIxLTcwOGUtOGNiNi04OGI3MjhlZGU4MDkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYTI4V24zbVN0IiwiY29nbml0bzp1c2VybmFtZSI6InZpc2l0b3IiLCJvcmlnaW5fanRpIjoiN2ZiY2YxZjctNWU2OS00NWFmLWIyZGUtY2VjZTZmNWYwNmE2IiwiYXVkIjoiMmtnbXY5dWFkbmZzNnFkZTY5cGQ3ZmJlMWwiLCJldmVudF9pZCI6Ijc4ZDZhYWZhLWMwYmQtNDMyNC1hNzU0LWI5YWMzYzBiYmU5MiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjkxNTk3MDcwLCJleHAiOjE2OTE2MDA2NzAsImlhdCI6MTY5MTU5NzA3MCwianRpIjoiYzQ3ZDY3OWEtYzI3OC00NzM4LWE0Y2EtNjE5MjE0ZWUzMDdiIiwiZW1haWwiOiJoYXJ1bnRya21uQGdtYWlsLmNvbSJ9.Vo_S6pFcmo44_wlfB0Zxx4rIMY4Ye8ii9eWoKBCiIdrFpoSAkfRpo09O5TBEin001NJbqoGmt0ueFgSjnrfglOhl4_AxHkSW4YC_MxFUqesCv34uC11ds6lBunPSDCb8fniMoCgcnswJFl4n42rrcRaxmauQnAD8bL7FpyOdgCmQtuLcif249AcSKSmw2XVJNdNYps3dVA-3wYILdCI9x6VgUIWk3qoH-2Lf01Rfw0MGRHHMd0Gal9RbfXfRaGfK2H7kLR0mrt3y_R18QixM_R4PvltNuOeO0YYh7L508GSDxc-BSvQOoHyTFDQ8hiG-4UOMR0eD9enXa7EGwYPOJw",
          // });
        }}
      >
        Misafir olarak devam et*
      </a>

      <p style={{ color: "gray", fontSize: "13px", textAlign: "center" }}>
        *beğeni ve yorum yapmak için hesap oluşturmalısın
      </p>

      <div
        style={{
          borderBottom: "1px solid gray",
          width: "340px",
          marginTop: "30px",
        }}
      ></div>
      <div style={{ width: "340px" }}>
        <p
          style={{
            fontFamily: "Ephesis",
            fontSize: "34px",
            marginBottom: "10px",
            color: "gray",
            textAlign: "center",
          }}
        >
          itügurme nedir?
        </p>

        <div style={infoContainer}>
          <p
            style={{
              textAlign: "left",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <span style={{ fontWeight: 800 }}>
              <a>itügurme,</a>
            </span>{" "}
            İstanbul Teknik Üniversitesi (İTÜ) yemekhanesinin günlük olarak
            değerlendirildiği bir sosyal platformdur. Kullanıcılar günün
            menüsünü değerlendirebilir, her yemeği ayrı ayrı puanlayıp
            yorumlayabilir.
          </p>
        </div>
        <div style={infoContainer2}>
          <p
            style={{
              textAlign: "left",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <span style={{ fontWeight: 800 }}>
              <a>gurme puanı:</a>
            </span>{" "}
            Kullanıcıların platformdaki aktivitesini yansıtan metriktir. Yorum
            yapma, yemek puanlama, yapılan yorumların aldığı beğeniler gibi
            birçok etmenin değerlendirilmesi sonucunda günlük olarak
            güncellenir.
          </p>
        </div>
        <div style={infoContainer}>
          <p
            style={{
              textAlign: "left",
              fontSize: "14px",
              color: "rgba(0, 0, 0, 0.8)",
            }}
          >
            <span style={{ fontWeight: 800 }}>
              <a>keşfet sayfası:</a>
            </span>{" "}
            Haftanın en çok beğenilen yemeklerinden oluşan haftanın menüsünün ve
            en beğenilen yorumların dinamik bir şekilde gösterildiği sayfadır.
          </p>
        </div>
        <div style={bottomBlank}></div>
        <AboutUs />
        <div style={bottomBlank}></div>
      </div>
    </div>
  );
}

// Signup.propTypes = {
//   setToken: PropTypes.func.isRequired,
// };

// STYLES
const headerContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  position: "fixed",
  width: "100%",
  top: "0",
  left: "0",
  borderBottom: "1px inset white",
  zIndex: "9999",
  //  top: 0; left: 0; width: 100%
};

const infoContainer = {
  backgroundColor: "#ebeaea",
  padding: "24px",
  paddingTop: "4px",
  paddingBottom: "4px",
  margin: "10px",
  marginTop: "36px",
  borderRadius: "30px",
  borderTopLeftRadius: "0px",
};

const infoContainer2 = {
  backgroundColor: "#ebeaea",
  padding: "24px",
  paddingTop: "4px",
  paddingBottom: "4px",
  margin: "10px",
  marginTop: "36px",
  borderRadius: "30px",
  borderTopRightRadius: "0px",
};

const bottomBlank = {
  height: "100px",
};

const formContainer = {
  marginTop: "0px",
  backgroundColor: "#ebeaea",
  //e3e1e1
  width: "300px",
  borderRadius: "10px",
};

const menuTextStyle = {
  fontFamily: "Ephesis",
  fontSize: "34px",
  marginTop: "80px",
  marginBottom: "10px",
  color: "gray",
};

const menuTextContainerStyle = {
  // backgroundColor: "red",
  // borderBottom: "1px outset gray",
  width: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const buttonStyle = {
  backgroundColor: "#843d3d",
  width: "200px",
  height: "40px",
  borderRadius: "10px",
  border: "none",
  color: "#e3e1e1",
  fontSize: "14px",
  // fontFamily: "Ubuntu",
  fontWeight: "bold",
  cursor: "pointer",
};
