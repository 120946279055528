import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
} from "react-icons/ai";

import URLContext from "./URLContext";

export default function SingleCommentForFood({
  comment,
  token,
  isLoggedIn,
  username,
}) {
  const [isLiked, setIsLiked] = useState(comment.isLiked);
  const [isDisliked, setIsDisliked] = useState(comment.isDisliked);
  const [score, setScore] = useState(comment.score);
  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isShowingReport, setIsShowingReport] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isReported, setIsReported] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // var token_ = token.Authorization;
  var initialScore = comment.score;
  if (comment.isLiked) {
    initialScore = initialScore - 1;
  } else if (comment.isDisliked) {
    initialScore = initialScore + 1;
  }

  var baseURL = useContext(URLContext);

  const commentOperation = (operation) => {
    var url = baseURL + "comment-vote";

    const headers = token;
    axios
      .post(
        url,
        {
          commentId: comment.commentId,
          operation,
          commenter: comment.commenter,
        },
        {
          headers,
        }
      )
      .then(() => {});
  };

  const deleteComment = () => {
    var url = baseURL + "/comment/delete";
    console.log(comment.commentId);
    const headers = token;
    axios
      .post(
        url,
        { commentId: comment.commentId },
        {
          headers,
        }
      )
      .then((response) => {
        setIsDeleted(true);
        setIsDeleting(false);
      })
      .catch((err) => console.error(err));
  };

  const handleReport = () => {
    setIsReporting(true);
    var url = baseURL + "comment/report";

    const headers = token;
    console.log(comment.commentId);
    console.log(headers);
    axios
      .post(
        url,
        { commentId: comment.commentId },
        {
          headers,
        }
      )
      .then((response) => {
        setIsReporting(false);
        setIsReported(true);
      })
      .catch((err) => console.error(err));
  };

  // useEffect(() => {
  //   setIsLoggedIn(
  //     JSON.parse(localStorage.getItem("token"))["Authorization"] !=
  //       "eyJraWQiOiJvOTRSOWhCQ2dKTXJhcHVycVFqa0cxZURyWHh0bFFyYUt0OXA1S2p6WFdnPSIsImFsZyI6IlJTMjU2In0.eyJzdWIiOiJlMzE0MzhmMi00MGIxLTcwOGUtOGNiNi04OGI3MjhlZGU4MDkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LWNlbnRyYWwtMS5hbWF6b25hd3MuY29tXC9ldS1jZW50cmFsLTFfYTI4V24zbVN0IiwiY29nbml0bzp1c2VybmFtZSI6InZpc2l0b3IiLCJvcmlnaW5fanRpIjoiN2ZiY2YxZjctNWU2OS00NWFmLWIyZGUtY2VjZTZmNWYwNmE2IiwiYXVkIjoiMmtnbXY5dWFkbmZzNnFkZTY5cGQ3ZmJlMWwiLCJldmVudF9pZCI6Ijc4ZDZhYWZhLWMwYmQtNDMyNC1hNzU0LWI5YWMzYzBiYmU5MiIsInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjkxNTk3MDcwLCJleHAiOjE2OTE2MDA2NzAsImlhdCI6MTY5MTU5NzA3MCwianRpIjoiYzQ3ZDY3OWEtYzI3OC00NzM4LWE0Y2EtNjE5MjE0ZWUzMDdiIiwiZW1haWwiOiJoYXJ1bnRya21uQGdtYWlsLmNvbSJ9.Vo_S6pFcmo44_wlfB0Zxx4rIMY4Ye8ii9eWoKBCiIdrFpoSAkfRpo09O5TBEin001NJbqoGmt0ueFgSjnrfglOhl4_AxHkSW4YC_MxFUqesCv34uC11ds6lBunPSDCb8fniMoCgcnswJFl4n42rrcRaxmauQnAD8bL7FpyOdgCmQtuLcif249AcSKSmw2XVJNdNYps3dVA-3wYILdCI9x6VgUIWk3qoH-2Lf01Rfw0MGRHHMd0Gal9RbfXfRaGfK2H7kLR0mrt3y_R18QixM_R4PvltNuOeO0YYh7L508GSDxc-BSvQOoHyTFDQ8hiG-4UOMR0eD9enXa7EGwYPOJw"
  //   );
  // }, []);

  return (
    <div key={comment.id} style={singleCommentContainer}>
      {!isShowingReport ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#843d3d",
            borderRadius: "10px",
            // borderBottomLeftRadius: "0px",
            width: "340px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
              height: "50px",
              borderBottom: "1px inset rgba(255, 255, 255, 0.2)",
              marginLeft: "10px",
            }}
          >
            <div
              style={{
                // fontFamily: "Ubuntu",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.4)",
                fontWeight: "bold",
                marginLeft: "10px",
                marginTop: "4px",
              }}
            >
              {comment.commenter}
            </div>
            {/* <div
              style={{
                // fontFamily: "Ubuntu",
                fontSize: "12px",
                color: "rgba(255, 255, 255, 0.3)",
                marginLeft: "10px",
              }}
            >
              gurme puanı: {comment.gurmeScore}
            </div> */}
            {isLoggedIn && (
              <div
                onClick={() => {
                  // deleteComment();

                  setIsShowingReport(true);
                }}
                style={{
                  // marginLeft: "10px",
                  marginRight: "8px",
                  fontSize: "18px",
                  color: "rgba(255, 255, 255, 0.3)",
                  // fontFamily: "Ubuntu",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
              >
                {/* <RiDeleteBin5Line size={18} style={{ margin: "4px" }} /> */}
                ...
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              // borderBottom: "1px inset white",
            }}
          >
            {" "}
            <p
              style={{
                // fontFamily: "Ubuntu",
                fontSize: "14px",
                color: "white",
                marginLeft: "20px",
              }}
            >
              {comment.text}
            </p>
          </div>
          {comment.imageUrl.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderTop: "2px rgba(255, 255, 255, 0.3) solid",
              }}
            >
              <a
                href={comment.imageUrl}
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: "flex" }}
              >
                <img
                  src={comment.imageUrl}
                  style={{
                    borderRadius: "10px",
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    // border: "1px white solid",
                    width: "100%",
                  }}
                />
              </a>
            </div>
          )}
        </div>
      ) : isDeleting ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "6",
            backgroundColor: "#843d3d",
            color: "rgba(255, 255, 255, 0.3",
            fontSize: "14px",
            borderRadius: "10px",
            height: "95px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Yorum siliniyor...
        </div>
      ) : isDeleted ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "6",
            backgroundColor: "#843d3d",
            color: "rgba(255, 255, 255, 1",
            fontSize: "14px",
            borderRadius: "10px",
            height: "95px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          Yorum silindi!
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: "6",
            backgroundColor: "#843d3d",
            borderRadius: "10px",
            height: "95px",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {isReporting ? (
            <div
              style={{
                margin: "4px",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.3)",
                textDecoration: "none",
              }}
            >
              Şikayet Ediliyor...
            </div>
          ) : !isReported ? (
            <div
              onClick={() => {
                // deleteComment();
                handleReport();
              }}
              style={{
                margin: "4px",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.9)",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Şikayet Et
            </div>
          ) : (
            <div
              style={{
                margin: "4px",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.3)",
                textDecoration: "none",
              }}
            >
              Şikayet Edildi!
            </div>
          )}
          {comment.commenter == username ? (
            <div
              onClick={() => {
                setIsDeleting(true);
                deleteComment();
              }}
              style={{
                margin: "4px",
                fontSize: "14px",
                color: "rgba(255, 255, 255, 0.9)",
                textDecoration: "none",
                cursor: "pointer",
              }}
            >
              Yorumu Sil
            </div>
          ) : null}
          <div
            onClick={() => {
              setIsShowingReport(false);
            }}
            style={{
              margin: "4px",
              fontSize: "14px",
              color: "rgba(255, 255, 255, 0.9)",
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Kapat
          </div>
        </div>
      )}
      {/* <div
        style={{
          display: "flex",
          //backgroundColor: "black",
          flex: "1",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "5px",
          paddingBottom: "5px",
          height: "95px",
        }}
      >
        {isLiked ? (
          <AiFillLike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("unlike");
              setScore(initialScore);
              setIsLiked(false);
            }}
            size="48"
          />
        ) : (
          <AiOutlineLike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              if (isLoggedIn) {
                commentOperation("like");
                setScore(initialScore + 1);
                setIsLiked(true);
                setIsDisliked(false);
              } else {
                localStorage.removeItem("token");
                window.location.reload();
              }
            }}
            size="48"
          />
        )}

        <p style={{ fontSize: "14px", color: "#843d3d" }}> {score} </p>
        {isDisliked ? (
          <AiFillDislike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              commentOperation("undislike");
              setScore(initialScore);
              setIsDisliked(false);
            }}
            size="48"
          />
        ) : (
          <AiOutlineDislike
            style={{ color: "#843d3d", cursor: "pointer" }}
            onClick={() => {
              if (isLoggedIn) {
                commentOperation("dislike");
                setScore(initialScore - 1);
                setIsLiked(false);
                setIsDisliked(true);
              } else {
                localStorage.removeItem("token");
                window.location.reload();
              }
            }}
            size="48"
          />
        )}
      </div> */}
    </div>
  );
}

// STYLES
const singleCommentContainer = {
  // backgroundColor: "#753838",
  display: "flex",
  width: "100%",
  borderRadius: "4px",
  marginTop: "15px",
  alignItems: "center",
  width: "340px",
};
