import React from "react";

const URLContext = React.createContext();

export const URLProvider = ({ children }) => {
  // const url = "https://itugurmeapi.herokuapp.com";
  const url = "https://3ws0hwaf3a.execute-api.eu-central-1.amazonaws.com/prod/";

  return <URLContext.Provider value={url}>{children}</URLContext.Provider>;
};

export default URLContext;
